export const DEFAULT_LAYOUT = "grid";

export const DEFAULT_TEMPLATE = [
  {
    tableNumber: 1,
    isActive: false,
    start: 0,
    end: 0,
    played: 0,
    discount: false,
    toPay: 0,
    pending: false,
  },
  {
    tableNumber: 2,
    isActive: false,
    start: 0,
    end: 0,
    played: 0,
    discount: false,
    toPay: 0,
    pending: false,
  },
  {
    tableNumber: 3,
    isActive: false,
    start: 0,
    end: 0,
    played: 0,
    discount: false,
    toPay: 0,
    pending: false,
  },
  {
    tableNumber: 4,
    isActive: false,
    start: 0,
    end: 0,
    played: 0,
    discount: false,
    toPay: 0,
    pending: false,
  },
  {
    tableNumber: 5,
    isActive: false,
    start: 0,
    end: 0,
    played: 0,
    discount: false,
    toPay: 0,
    pending: false,
  },
  {
    tableNumber: 6,
    isActive: false,
    start: 0,
    end: 0,
    played: 0,
    discount: false,
    toPay: 0,
    pending: false,
  },
  {
    tableNumber: 7,
    isActive: false,
    start: 0,
    end: 0,
    played: 0,
    discount: false,
    toPay: 0,
    pending: false,
  },
  {
    tableNumber: 8,
    isActive: false,
    start: 0,
    end: 0,
    played: 0,
    discount: false,
    toPay: 0,
    pending: false,
  },
  {
    tableNumber: 9,
    isActive: false,
    start: 0,
    end: 0,
    played: 0,
    discount: false,
    toPay: 0,
    pending: false,
  },
  {
    tableNumber: 10,
    isActive: false,
    start: 0,
    end: 0,
    played: 0,
    discount: false,
    toPay: 0,
    pending: false,
  },
  {
    tableNumber: 11,
    isActive: false,
    start: 0,
    end: 0,
    played: 0,
    discount: false,
    toPay: 0,
    pending: false,
  },
  {
    tableNumber: 12,
    isActive: false,
    start: 0,
    end: 0,
    played: 0,
    discount: false,
    toPay: 0,
    pending: false,
  },
];
